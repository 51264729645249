.ig-slick-prev, .ig-slick-next {
    position: absolute;
    top: 40%;
    z-index: 100;
    cursor: pointer;
    border-radius:40px;
    background: #000;
    line-height: 0;
    padding: 5px;
    opacity: 0.5;
    transition:0.5s;
}



.ig-slick-prev svg, .ig-slick-next svg {
    width:25px;
    height:25px;
}

.ig-slick-prev {
    left: 5px;
}
.ig-slick-next {
    right: 5px;
}

#ig-slider-feed-vw {
}

.ig-slider-feed {

}

.vw-ig-insta-icon {
    margin:auto;
    display: block;
}

.ig-slider-feed-element {
    box-sizing: border-box;
    overflow:hidden;
    background-color: black;
    position:relative;
}

.ig-slider-feed-element-image {
    width:100%;
    transition:0.5s;
    background-position: center center;
}

.ig-slider-feed-element-image:before {
	content: "";
	display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
}



.ig-slider-feed-element .ig-slider-feed-element-meta {
    position: absolute;
    width: 100%;
    z-index:100;
    top:0;
    opacity:0.0;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ig-slider-feed-element-meta:before {
    content: "";
	display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
}

.ig-slider-feed-element-likes-container, .ig-slider-feed-element-comments-container {
    padding:10px;
}

.ig-slider-feed-element .ig-slider-feed-element-meta p {
    text-align: center;
    font-size:16px;
    padding-top:5px;
}

.ig-slider-feed .ig-slider-feed-element p {
    font-family: sans-serif;
    word-wrap: break-word;
}

.ig-slider-feed-element .ig-slider-feed-element-likes, .ig-slider-feed-element .ig-slider-feed-element-comments {
    font-family: sans-serif;
    color: #fff !important;   
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@media (hover: hover) {
    .ig-slick-prev:hover, .ig-slick-next:hover {
        opacity: 0.9;
    }

    .ig-slider-feed-element:hover .ig-slider-feed-element-image {
        opacity:0.2;
        transform:scale(1.1);
    }

    .ig-slider-feed-element:hover .ig-slider-feed-element-meta {
        opacity:1.0;
        transform:scale(1.1);
    }
    
    .ig-slider-feed-element img:hover {
        opacity:0.5;
    }
}
