.Polaris-Card {
    margin-top:10px;
}

.Polaris-Layout {
    margin-top:20px;
}

.main-spinner {
    margin-top:7px;
}

div.edit {
    margin-top: 5px;
}

div.mt {
    margin-top: 15px;
}