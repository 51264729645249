.Polaris-Card {
    margin-top:10px;
}

.Polaris-Layout {
    margin-top:20px;
}

.main-spinner {
    margin-top:7px;
}

div.edit {
    margin-top: 5px;
}

.img-instructions {
    width: 100%;
    padding:20px 15px;
}